import http from '@/http';

export default {
    state: {
        contactUpdateLoading: false
    },
    getters: {
        contactLoading: state => state.contactUpdateLoading,
    },
    mutations: {
        setContactLoading(state, data) {
            state.contactUpdateLoading = data;
        },
    },
    actions: {
        createContact({ commit }, contactData) {
            commit("setContactLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').post(`contacts`, contactData).then(response => {
                    commit("setContactLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setContactLoading", false);
                    rej(error)
                });
            });
        },
        updateContact({ commit }, contactData) {
            commit("setContactLoading", true);
            return new Promise((res, rej) => {
                http('salesforce').put(`contacts/${contactData.id}`, contactData).then(response => {
                    commit("setContactLoading", false);
                    res(response);
                }).catch((error) => {
                    commit("setContactLoading", false);
                    rej(error)
                });
            });
        }
    },

};
